import React from 'react';
import { SEO, HeroText, Layout, Title, Services } from 'components';

export default ({ location }) => (
  <Layout location={location}>
    <SEO
      pageSpecificTitle="Services"
      pageSpecificDescription="We offer a wide range of services from video production to video strategy and everything in-between."
      pathname={location.pathname}
    />
    <div style={{ paddingTop: '160px' }}>
      <Title text="Our Services" pathname={location.pathname} />
      <HeroText>
        We offer a wide range of services, from video production to live stream, strategy and
        <br />
        everything in-between.
      </HeroText>
      <Services />
    </div>
  </Layout>
);
